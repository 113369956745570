export const vapidKey =
  'BA6P-nOcJKoWZRshkQZFbB-bkEkeuqJf3HLSAhkZvmar7-5U0Owpx1__KEqYQuulXRGMXtgt99ovOSOdvYCFDMs';
export const config = {
  apiKey: 'AIzaSyAk58aeEJjHwrevgM4qOtXREdUO8zjVdeM',
  authDomain: 'prod-rx-cfs.firebaseapp.com',
  projectId: 'prod-rx-cfs',
  storageBucket: 'prod-rx-cfs.appspot.com',
  messagingSenderId: '797996324409',
  appId: '1:797996324409:web:034582ef9a874506e15fe8',
  measurementId: 'G-ZYQHEBRP67'
};