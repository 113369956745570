export const addressParser = {
  api: 'https://address-parser-api.cfstrans.com/',
  key: 'bv80K4C5IGjUc604MxqIO_LB6k_mU5m4CuwQeikm',
  unique_address_service: {
    name: 'address_parser-PRODUCTION-placekey_plugin_lambda',
    apply: true
  },
  geocode_service: {
    name: 'address_parser-PRODUCTION-google_plugin_lambda',
    apply: true
  },
  centers: {
    apply: true
  },
  typos: {
    apply: true
  }
};
export const apiEndPoint = 'https://rx-api.cfstrans.com/';
export const base_url = `${location.origin}/#/`;
export const layersEndPoint = 'https://rx.cfstrans.com/';
export const map_apiKey = 'AIzaSyBJ8ELnXMj8d8UGZFcBnASZrQLg_CQAuUI';
export const fligthmap_apiKey = '4a306390-2c4a-11ec-9a5a-8d77438946f8';
export default {
  addressParser,
  apiEndPoint,
  base_url,
  layersEndPoint,
  map_apiKey,
  fligthmap_apiKey
};
