<template>
  <div class="table-container">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
    <div v-if="header" class="table-title">
      <p>
        {{ this.header }} <small v-if="pagination.total">{{ `(${pagination.total})` }}</small>
      </p>
    </div>
    <template v-if="$slots.empty && isEmpty">
      <slot name="empty" />
    </template>
    <b-table
      v-else
      :data="tableData"
      :loading="false"
      :paginated="infiniteScroll ? false : paginated"
      :sticky-header="true"
      :total="pagination.total"
      :class="classes"
      :focusable="focusable"
      @page-change="onPageChange"
      @select="onSelect"
      @click="(data) => $emit('click', data)"
      @check="(data) => $emit('check', data)"
      @check-all="(data) => $emit('checkAll', data)"
      :selected="selected"
      backend-pagination
      class="base-table"
      pagination-size="is-small"
      ref="bTable"
      :striped="striped"
      :checked-rows.sync="oCheckedRows"
      v-bind="{ ...$props, ...$attrs }"
      :row-class="(row, index) => this.isRowClass && classRow(row)"
    >
      <slot />
      <template #empty>
        <Empty v-if="!isLoading" />
      </template>
      <template slot="detail" slot-scope="props">
        <slot name="details" v-bind="{ row: props.row }" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { Empty } from '@/components';
export default {
  components: {
    Empty
  },
  mounted() {
    this.getData();
    if (this.infiniteScroll) this.addScrollEvents();
    if (this.intervalSeconds) {
      this.startAutoUpdate();
    }
  },
  beforeDestroy() {
    this.cancelRequest();
    this.stopAutoUpdate();
    clearTimeout(this.updateTimeout);
    this.removeScrollEvents();
  },
  data() {
    return {
      selected: null,
      updateTimeout: null,
      interval: null,
      cancelToken: null,
      scrollIsActive: false,
      scrollTimeout: 0,
      isLoading: this.loading,
      pagination: { total: 0, perPage: this.perPage, currentPage: 1 },
      paginationInfiniteScroll: { total: 0, perPage: this.perPage, currentPage: 1 },
      tableData: this.data,
      oCheckedRows: []
    };
  },
  computed: {
    allowGetData() {
      return !(this.paramsRequired && !this.params.length);
    },
    searchApi() {
      const { apiUrl, params } = this;
      if (typeof apiUrl === 'function') return apiUrl(params);
      return apiUrl;
    },
    autoUpdateInfiniteScroll() {
      return this.infiniteScroll && this.intervalSeconds;
    },
    classes() {
      let classes = [];
      if (this.$listeners.click) classes.push('is-click');
      if (this.$listeners.select) classes.push('is-selectable');
      classes.push(this.isEmpty ? 'is-empty' : 'no-empty');
      return classes.join(' ');
    },
    isEmpty() {
      return !this.tableData.length && !this.loading;
    },
    intervalSeconds() {
      let { updateInterval } = this;
      if (updateInterval && updateInterval < 1000) updateInterval = updateInterval * 1000;
      return updateInterval;
    },
    bTable() {
      return this.$refs?.bTable;
    },
    queryParams() {
      const { currentPage, perPage } = this.pagination;
      const aParams = [`page=${currentPage}`, `per_page=${perPage}`, ...this.params];
      return `?${aParams.join('&')}`;
    },
    queryParamsInfiniteScroll() {
      let { currentPage, perPage } = this.paginationInfiniteScroll;
      const aParams = [`page=1`, `per_page=${perPage * currentPage}`, ...this.params];
      return `?${aParams.join('&')}`;
    }
  },
  methods: {
    tableWrapper() {
      return this.$refs?.bTable?.$el.getElementsByClassName('table-wrapper')[0];
    },
    addScrollEvents(element) {
      if (element) {
        if (this.scrollIsActive) this.removeScrollEvents(element);
        element?.addEventListener('scroll', this.handleScroll);
        this.scrollIsActive = true;
      }
    },
    startAutoUpdate() {
      this.stopAutoUpdate();
      if (this.intervalSeconds)
        this.interval = setInterval(() => {
          this.updateDataTable();
        }, this.intervalSeconds);
    },
    stopAutoUpdate() {
      clearInterval(this.interval);
    },
    removeScrollEvents(element) {
      if (element) {
        element.removeEventListener('scroll', this.handleScroll);
        this.scrollIsActive = false;
      }
    },
    resetTableData() {
      this.pagination.currentPage = 1;
      this.tableData = [];
      this.oCheckedRows = [];
      this.onSelect({});
    },
    resetScrollEvents() {
      const tableWrapper = this.tableWrapper();
      if (this.infiniteScroll && tableWrapper) {
        this.addScrollEvents(tableWrapper);
      }
    },
    classRow(stringValue) {
      if (stringValue?.ready_to_assign !== 1) {
        const data = JSON.parse(stringValue?.result_import);
        return data.result?.toLowerCase();
      }
      return false;
    },
    cancelRequest(message = 'Avoid multiple') {
      if (this.cancelToken) {
        this.cancelToken.cancel(message);
        this.isLoading = false;
      }
    },
    getSelectedById(itemId) {
      return this.tableData.find((item) => item[this.idKey] === itemId);
    },
    handleScroll(e) {
      clearTimeout(this.scrollTimeout);
      const { scrollHeight, offsetHeight, scrollTop } = e.target;
      const scroll = scrollTop + offsetHeight;
      this.scrollTimeout = setTimeout(() => {
        if (scroll > scrollHeight - 200) this.nextPage();
      }, 300);
    },
    nextPage() {
      const hasReachTotal = this.tableData.length >= this.pagination.total;
      if (hasReachTotal || this.isLoading) return;
      this.pagination.currentPage += 1;
      this.onPageChange(this.pagination.currentPage);
    },
    async onPageChange(page) {
      this.$emit('page-change', page);
      this.pagination.currentPage = page;
      await this.getTableData();
      this.reselect();
    },
    onSelect(item) {
      if (this.$listeners.select) {
        this.selected = item;
        this.$emit('select', item);
      }
    },
    removeRow({ key, value }) {
      this.tableData = this.tableData.filter((row) => row[key] != value);
      this.pagination.total = this.pagination.total - 1;
      this.$emit('setTotalItems', this.pagination.total);
    },
    reCheck() {
      if (!this.checkable) return;
      const newChecked = [];
      this.oCheckedRows.map((row) => {
        const match = this.tableData.find((dRow) => dRow[this.idKey] == row[this.idKey]);
        if (match) newChecked.push(match);
      });
      this.oCheckedRows = newChecked;
    },
    reselect() {
      if (this.selected?.id) this.selected = this.getSelectedById(this.selected.id);
    },
    async getData(getParams = { clear: false } || {}) {
      this.cancelRequest();
      if (getParams === true) getParams = { clear: true };
      const { clear, hideLoading } = getParams;

      if (this.searchApi && this.allowGetData) {
        this.cancelToken = this.Api.cancelToken;
        this.getTotal();
        this.getTableData(hideLoading);
        if (clear) this.resetTableData();
        this.resetScrollEvents();
      }
    },
    async fullReload({ showLoader = true } = {}) {
      this.getTotal();
      await this.getTableData(true, false, showLoader);
    },
    backgroundUpdate({ showLoader = false } = {}) {
      this.getTotal();
      this.getTableData(true, false, showLoader);
    },
    reload() {
      this.getTableData();
    },
    updateDataTable() {
      if (this.tableData.length >= 1) this.backgroundUpdate();
      else this.getData();
    },
    async getTableData(isUpdate, onNextPage, showLoader) {
      const { searchApi, infiniteScroll } = this;
      if (!isUpdate || onNextPage || showLoader) this.isLoading = true;
      try {
        let { data } = await this.Api.get(
          `${searchApi}${
            infiniteScroll && isUpdate ? this.queryParamsInfiniteScroll : this.queryParams
          }`,
          {
            cancelToken: this.cancelToken.token
          }
        );
        const oData = this.dataPreProcessor ? this.dataPreProcessor(data || []) : data || [];
        if (infiniteScroll) {
          this.tableData = isUpdate ? oData : [...this.tableData, ...oData];
          this.reselect();
        } else {
          this.tableData = oData;
          this.onSelect({});
        }
        this.reCheck();
        if (!isUpdate) this.$emit('loadedItems', oData);
        this.isLoading = false;
      } catch (error) {
        if (error?.aborted) console.warn(error);
        else {
          this.isLoading = false;
          console.error(error);
        }
      }
    },
    async getTotal() {
      try {
        const { perPage, currentPage, total } = this.pagination;
        const { data } = await this.Api.get(
          `/${this.searchApi}/total_items${
            this.autoUpdateInfiniteScroll ? this.queryParamsInfiniteScroll : this.queryParams
          }`,
          {
            cancelToken: this.cancelToken.token
          }
        );
        const totalItems = ((data && data[0]) || data).total_items;
        const newMaxPage = totalItems / perPage;
        const newMaxPageCeil = Math.ceil(newMaxPage) || 1;
        const updateCurrentPage = newMaxPageCeil < currentPage;
        const updateTotalItems = total != totalItems;
        if (updateCurrentPage) this.pagination.currentPage = newMaxPageCeil;
        if (updateTotalItems) {
          this.pagination.total = totalItems;
          this.$emit('setTotalItems', this.pagination.total);
        }
      } catch (error) {
        if (error?.aborted) console.warn(error);
        else console.error(error);
      }
    }
  },
  watch: {
    searchApi() {
      this.reload();
    },
    loading(value) {
      this.isLoading = value;
    },
    intervalSeconds(value) {
      this.$nextTick(() => {
        this.startAutoUpdate(value);
      });
    },
    isEmpty(value) {
      this.resetScrollEvents(value);
    },
    isLoading(value) {
      this.$emit('update:loading', value);
    },
    params(newVals, oldVals) {
      if (newVals.join(',') === oldVals.join(',')) return;
      this.pagination.currentPage = 1;
      this.getData({ clear: true });
    },
    'pagination.currentPage'(value) {
      this.paginationInfiniteScroll.currentPage = value;
    },
    checkedRows(value) {
      this.oCheckedRows = value;
    },
    oCheckedRows(value) {
      this.$emit('update:checked-rows', value);
    }
  },
  props: {
    apiUrl: { type: [String, Function], default: '' },
    checkedRows: { type: Array, default: () => [] },
    data: { type: Array, default: () => [] },
    dataPreProcessor: { type: Function, default: null },
    checkable: { type: Boolean, default: false },
    focusable: { type: Boolean, default: true },
    infiniteScroll: { type: Boolean, default: false },
    idKey: { type: String, default: 'id' },
    loading: { type: Boolean, default: false },
    paginated: { type: Boolean, default: false },
    params: { type: Array, default: () => [] },
    paramsRequired: { type: Boolean, default: false },
    perPage: { type: Number, default: 15 },
    header: { type: String, default: '' },
    updateInterval: { type: Number, default: 0 },
    isRowClass: { type: Boolean, default: false },
    striped: { type: Boolean, default: true },
    totalItemsCounter: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.table-container
  position: relative
  overflow: visible
  height: 100%
  z-index: 1
  .table-title
    p
      font-weight: bold
      font-size: $f-xxl
      padding-bottom: 5px
      margin-bottom: 12px
      border-bottom: 1px solid $gray-300
      small
        font-size: $f-lg
.base-table
  display: flex
  flex-flow: column
  height: 100%
  ::v-deep &.no-empty .table-wrapper table
    height: auto
  &.is-click,
  &.is-selectable
    ::v-deep
      td
        cursor: pointer!important
  .inactive
    opacity: .3
  ::v-deep
    &.is-empty td
      // height: calc(100vh - 320px)
      height: 91%
      padding: 8px 0px
    &:hover
      tr,td
        transition: none
    th,
    td
      border: none
      &:first-child
        border-radius: $br-md 0 0 $br-md
      &:last-child
        border-radius: 0 $br-md $br-md 0
    .table-wrapper
      min-height: 400px
      height: calc(100% - 50px)
      // height: calc(100vh - 125px)
      table
        height: 100%
        border: none!important
      // tr.is-selected
        // td:first-child
        //   border-left: 1px solid $blue-400
        // td
        //   border-top: 1px solid $blue-400
        //   border-bottom: 1px solid $blue-400
        // td:last-child
        //   border-right: 1px solid $blue-400
        // border-color: transparent!important
      thead
        height: 30px
        .checkbox-cell
          padding-top: 5px!important
      td
        vertical-align: middle
      .isOpen
        background: $blue-100
    .detail
      margin-bottom: 10px
      box-shadow: none!important
      background: transparentize( $blue-100,0.4) !important
      td
        border-radius: 0 0 $br-md $br-md!important
    .error,.warning
      .isOpen
        background: transparent !important
    .error + .detail
      background: transparentize($red-100,0.5) !important
    .warning + .detail
      background: transparentize($yellow-100,0.4) !important
</style>
